import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        accent: '#385F73',
        add: colors.blue.darken2,
        alert: colors.orange,
        base: colors.shades.white,
        button: colors.shades.white,
        delete: colors.red,
        edit: colors.orange,
        navLink: colors.deepOrange.darken3,
        highlight: '#A4AC96',
        fill: colors.grey.lighten4,
        input: colors.shades.white,
        main: colors.blueGrey.darken4,
        primary: '#2589BD',
        newBtn: colors.blue.darken2,
        save: colors.green,
        toolbar: colors.grey.lighten4
      }
    }
  }
})
