import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    app: {},
    userAuthKey: '',
    snackbar: {}
  },
  mutations: {
    setApp(state, payload) {
      state.app = payload
    },
    setBasicAuthKey(state, payload) {
      state.basicAuthKey = payload
    },
    setUserAuthKey(state, payload) {
      state.userAuthKey = payload
    },
    setSnackbar(state, payload) {
      state.snackbar = payload
    }
  },
  actions: {
    showSnackbar({ commit }, snackbar) {
      snackbar.showing = true
      snackbar.timeout = 5000
      snackbar.icon = snackbar.status === 'success' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-alert-circle-outline'
      snackbar.color = snackbar.status === 'success' ? 'success' : snackbar.status ? 'amber darken-2' : 'error'
      commit('setSnackbar', snackbar)
    }
  }
})

export default store