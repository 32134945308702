import axios from 'axios'
import AuthToken from '@/auth/auth-token'
// import qs from 'qs'

class OrderGrid {
  constructor() {
    const base = axios.create({
      baseURL: process.env.VUE_APP_ADMAN_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.orderGrid = base
  }

  async getAdPlanners(party_id, offset = 0, limit = 25) {
    await AuthToken.setAuth(this.orderGrid, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.orderGrid({
      url: '/ad_planners',
      method: 'GET',
      params: { party_id, offset, limit }
    })
  }

  async getPages() {
    return this.orderGrid({
      url: `/pages`,
      method: 'GET'
    })
  }

  async getFeaturePositions() {
    return this.orderGrid({
      url: `/adFeatureTypes`,
      method: 'GET'
    })
  }

  async getDepartments(ad_id) {
    await AuthToken.setAuth(this.orderGrid, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.orderGrid({
      url: `/ad_items_department/${ad_id}`,
      method: 'GET'
    })
  }

  async queryData(payload) {
    return this.orderGrid({
      url: '/order/item_history',
      method: 'POST',
      data: payload
    })
  }

  async getUserStoresById(userId) {
    return this.orderGrid({
      url: `/user_stores/${userId}`,
      method: 'GET'
    })
  }

  async getAds(storeId) {
    return this.orderGrid({
      url: `/order/ads/${storeId}`,
      method: 'GET'
    })
  }

  async updateOrderStatus(data) {
    return this.orderGrid({
      url: 'order/status',
      method: 'PUT',
      data: data
    })
  }

  async getOrderGridData(adId, storeId, offset = 0, limit = 25, params) {
    return this.orderGrid({
      url: `/order/ad/${adId}/${storeId}/${limit}/${offset}`,
      method: 'GET',
      params: params
      // paramsSerializer: params => {
      //   return qs.stringify(params, { indices: false, encode: false })
      // }
    })
  }

  async getShipperComponents(itemCode) {
    return this.orderGrid({
      url: `/order/components/${itemCode}/18`,
      method: 'GET'
    })
  }

  async getProductLinkedGroup(itemCode, supplierId) {
    return this.orderGrid({
      url: `/productLinkGroup/${itemCode}/${supplierId}`,
      method: 'GET'
    })
  }

  async updateSuggestedOrder(data) {
    return this.orderGrid({
      url: '/order/calculate',
      method: 'POST',
      data: data
    })
  }

  async createOrder(data) {
    return this.orderGrid({
      url: '/order/save_item',
      method: 'POST',
      data: data
    })
  }

  async updateOrder(data) {
    return this.orderGrid({
      url: '/order/save_item',
      method: 'PUT',
      data: data
    })
  }

  async deleteOrder(data) {
    return this.orderGrid({
      url: '/order/save_item',
      method: 'DELETE',
      data: data
    })
  }

  async exportStoresOrder(data) {
    return this.orderGrid({
      url: '/orders/export',
      method: 'POST',
      data: data
    })
  }

  async getExported(userEmail) {
    return this.orderGrid({
      url: `/orders/exports/${userEmail}`,
      method: 'GET'
    })
  }

  async saveFeatureNote(data, method) {
    return this.orderGrid({
      url: '/order/feature_note',
      method: method,
      data: data
    })
  }

  async resetOrders(ad_id, store_id, data) {
    return this.orderGrid({
      url: `/order/reset_orders/${ad_id}/${store_id}`,
      method: 'DELETE',
      data: data
    })
  }

  // async getShipperComponents(ad_id, feature_id, shipper_id) {
  //   await AuthToken.setAuth(this.orderGrid, {
  //     audience: process.env.VUE_APP_PROMO_AUDIENCE
  //   })
  //   return this.orderGrid({
  //     url: `/ad_planner/shipper_components/${ad_id}/${feature_id}`,
  //     method: 'GET',
  //     params: { shipper_id }
  //   })
  // }
}

export default new OrderGrid()
