<template>
  <v-overlay v-if="$auth.isLoading || !$auth.isAuthenticated">
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay>

  <v-app v-else>
    <v-app-bar app color="main" dark>
      <v-img
        max-height="75"
        max-width="100"
        src="@/assets/arg_logo_white.png"
        alt="ARG Logo" />
      <v-spacer></v-spacer>
      <v-btn text class="text-capitalize" @click="logout">
        Sign Out
        <v-icon right>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="toolbar">
      <alertbar></alertbar>
      <router-view v-if="hasPortalAccess" />
      <v-alert v-else type="error" text border="left">
        You don't have authorized roles to access this site.
      </v-alert>
      <v-snackbar
        top
        rounded="md"
        height="60"
        style="top: 70px"
        content-class="body-1"
        transition="scale-transition"
        v-model="snackbar.showing"
        :timeout="snackbar.timeout"
        :color="snackbar.color">
        <v-icon left>{{ snackbar.icon }}</v-icon> {{ snackbar.message }}
      </v-snackbar>
    </v-main>
    <v-footer color="main" app class="justify-space-between">
      <span class="white--text">
        iPro <span class="registeredTrademark">&reg;</span> Systems, LLC. &copy;
        {{ new Date().getFullYear() }}
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import alertbar from '@/components/dialogs/alertbar.vue'
import { mapState } from 'vuex'
import { userAccess } from '@/mixins/user-access'

export default {
  name: 'App',
  mixins: [userAccess],
  components: { alertbar },
  computed: {
    ...mapState(['snackbar'])
  },
  methods: {
    logout() {
      sessionStorage.removeItem('order_grid_store')
      sessionStorage.removeItem('order_grid_store')
      sessionStorage.removeItem('search-filters')
      this.$auth.logout({
        returnTo: `${window.location.origin}`
      })
    }
  }
}
</script>
