import Vue from 'vue'
import VueRouter from 'vue-router'
import OrderView from '../views/OrderView.vue'

// authentication guard
import { authGuard } from '@/auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/orders',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'OrderView',
    component: OrderView,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  to.meta.referrer = from.name
  if (to.meta) {
    // Use the route's "meta.title" value to assign the page's title
    if (to.meta.title) document.title = to.meta.title
    // For routes requiring authentication ( has "meta.requiresAuth" property )
    if (to.meta.requiresAuth === true) {
      return authGuard(to, from, next)
    }
    return next()
  } else {
    return next()
  }
})

export default router
