export const userAccess = {
  computed: {
    entities() {
      return ['admin', 'ipro-portal.retailer', 'ipro-portal.retailer-edge', 'ipro-portal.retailer-edge-manager']
    },
    userRoles() {
      return this.$auth.userRoles || []
    },
    adminRoles() {
      return ['admin']
    },
    ownerRoles() {
      return ['owner']
    },
    iProEdgeRoles() {
      return ['ipro-portal.retailer-edge-manager', 'ipro-portal.retailer-edge']
    },
    iProEdgeManager() {
      return ['ipro-portal.retailer-edge-manager']
    },
    hasPortalAccess() {
      return this.userRoles.some(scope => this.entities.includes(scope))
    },
    adminAccess() {
      return this.userRoles.some(scope => this.adminRoles.includes(scope))
    },
    ownerAccess() {
      return this.userRoles.some(scope => this.ownerRoles.includes(scope))
    },
    iProEdgeAccess() {
      return this.userRoles.some(scope => this.iProEdgeRoles.includes(scope))
    },
    iProEdgeManagementAccess() {
      return this.userRoles.some(scope => this.iProEdgeManager.includes(scope))
    }
  }
}
