<template>
  <v-container fluid class="ma-0 pa-0" v-resize="onResize">
    <v-data-table
      :headers="headers.filter(({ show }) => show)"
      :height="tableHeight"
      :items.sync="groupedItems"
      :item-class="rowHighlight"
      :items-per-page="pageSize"
      dense
      fixed-header
      hide-default-footer
      loading-text="Loading... Please Wait"
      no-data-text="No Ad Items to Display"
      no-results-text="No Matches Found"
      item-key="item_id"
      show-select
      :expanded.sync="expanded"
      @click:row="highlighted = $event"
      group-by="combinedKey"
      v-model="selected"
      sort-by="last_90_days_shipped_qty"
      sort-desc>
      <template #top>
        <v-toolbar flat color="toolbar">
          <v-row align="center" dense>
            <v-col lg="3" xl="2">
              <v-autocomplete
                outlined
                dense
                background-color="input"
                hide-details
                label="Store"
                :items="stores"
                item-text="name"
                item-value="id"
                return-object
                v-model="store" />
            </v-col>
            <v-col lg="3" xl="2" class="pr-0">
              <v-autocomplete
                outlined
                dense
                background-color="input"
                hide-details
                :class="iProEdgeAccess ? 'rounded-r-0' : ''"
                label="Ad"
                :items="ads"
                item-text="name"
                item-value="id"
                return-object
                v-model="ad">
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ data.item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="black--text">
                      <span class="font-weight-bold"> Due Date: </span>
                      {{ getOrderDeadlines(data.item.ad_date).date }}
                      <br />
                      <span class="font-weight-bold"> Status: </span>
                      <span
                        :class="`${
                          getOrderDeadlines(data.item.ad_date).color
                        }--text`"
                        size="10"
                        left>
                        {{
                          getOrderDeadlines(data.item.ad_date).open
                            ? 'OPEN'
                            : 'CLOSED'
                        }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="iProEdgeAccess"
              class="pl-0"
              lg="2"
              xl="2"
              style="max-width: 150px">
              <v-autocomplete
                :readonly="orderingClosed || !iProEdgeManagementAccess"
                outlined
                dense
                background-color="input"
                hide-details
                label="Status"
                :class="iProEdgeAccess ? 'rounded-l-0' : ''"
                :items="['Draft', 'Submitted']"
                :value="ad.order_status ? ad.order_status.status : 'Draft'"
                @change="updateOrderStatus">
              </v-autocomplete>
            </v-col>
            <v-tooltip v-if="iProEdgeAccess && !readOnlyView" top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="selected.length < 1"
                  class="amber darken-3 white--text ml-2 text-capitalize"
                  @click.stop="barChart = true">
                  <v-icon>mdi-chart-timeline-variant</v-icon>
                </v-btn>
              </template>
              <span>Suggested Order</span>
            </v-tooltip>
            <v-col
              v-if="iProEdgeAccess && !readOnlyView"
              class="pl-3"
              lg="2"
              xl="2">
              <v-btn 
              class="mr-2 text-capitalize"
              color="error"
              :disabled="loading"
              @click="resetOrdersModal = true">
                Reset Total to Original Suggestion
              </v-btn>
            </v-col>
            <v-spacer />
            <v-btn class="mr-2 text-capitalize" @click="exportModal = true">
              <v-icon left>mdi-file-excel-outline</v-icon>
              Exports
            </v-btn>

            <SearchFilters
              :ad="ad"
              :departments="departments"
              :pages="pages"
              :featureTypes="featureTypes"
              :featureTexts="featureTexts"
              :isShipper="isShipper"
              :quantityFilter="quantityFilter"
              v-model="searchParams" />
          </v-row>
        </v-toolbar>
      </template>
      <template #[`header.data-table-select`]="{ on, props }">
        <v-badge
          v-if="iProEdgeAccess && !readOnlyView"
          color="amber darken-3"
          :value="selected.length > 0"
          :content="selected.length"
          inline>
          <v-simple-checkbox
            :ripple="false"
            v-on="on"
            v-bind="props"
            dark
            @click="toggleAllItems">
          </v-simple-checkbox>
        </v-badge>
      </template>
      <template #[`group.header`]="{ group, headers, items, isOpen, toggle }">
        <td
          :colspan="headers.length"
          style="
            height: 40px;

            border-top: 1px solid grey !important;
            cursor: pointer;
          "
          class="blue-grey lighten-2 white--text">
          <v-row dense align="center">
            <v-btn
              dark
              @click="toggle"
              small
              icon
              :ref="group"
              :data-open="isOpen">
              <v-icon>
                {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
            <span class="ml-1 font-weight-medium">
              {{ group }}
            </span>
            <a
              v-if="iProEdgeAccess && !readOnlyView"
              href="#"
              text
              class="white--text ml-2 font-weight-medium text-capitalize"
              @click.stop="toggleItemSelection(items)">
              {{ itemsInGroup(items) ? 'Clear All' : 'Select All' }}
            </a>

            <v-divider inset vertical class="mx-4 mb-1 white" />
            <v-tooltip color="main" top>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :value="items.some(({ feature_note }) => feature_note)"
                  right
                  color="amber darken-2"
                  overlap
                  dot
                  offset-x="15"
                  offset-y="15">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="openNote(items, group)">
                    <v-icon color="white">mdi-note-text-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <span> Notes </span>
            </v-tooltip>
          </v-row>
        </td>
      </template>

      <template #[`item.data-table-select`]="{ item, isSelected }">
        <div class="text-center" style="display: flex">
          <v-simple-checkbox
            v-if="iProEdgeAccess && isOpenStock(item) && !readOnlyView"
            class="mb-1"
            :ripple="false"
            :value="
              selected.some(
                selectedItem =>
                  selectedItem && selectedItem.item_id == item.item_id
              )
            "
            @click.stop="addToSelectedItems(isSelected, item)" />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="iProEdgeAccess && isOpenStock(item) && !readOnlyView"
                tabindex="-1"
                v-on="on"
                small
                class="mr-2 mb-1"
                icon
                @click.stop="getProductLinkedGroup(item)">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </template>
            <span>Linked Group</span>
          </v-tooltip>
        </div>
      </template>

      <template #expanded-item="{ headers, item }">
        <td
          @click="highlighted = item"
          :colspan="headers.length"
          class="px-0"
          style="cursor: pointer">
          <div
            class="px-4"
            :style="{
              borderBottom:
                item.item_id === highlighted.item_id
                  ? '2px solid primary'
                  : null,
              paddingTop: item.item_id === highlighted.item_id ? '3px' : null
            }">
            <v-row
              v-if="items.length && showDeliveryDates"
              dense
              align="center"
              class="mt-1">
              <v-col
                cols="1"
                style="height: 50px"
                class="pt-1 mb-3"
                v-for="order in item.item_orders"
                :key="order.item_id">
                <v-text-field
                  :readonly="readOnlyView"
                  hide-details
                  outlined
                  :background-color="
                    order.processed == 'true' ? 'grey lighten-3' : 'white'
                  "
                  class="deliveryDay mt-2"
                  dense
                  type="number"
                  :color="order.processed == 'true' ? 'grey darken-1' : ''"
                  :label="formatLabel(order.delivery_date, order)"
                  @focus="activeInput = order"
                  @change="selectMethod(item, order, $event)"
                  :value="order.quantity > 0 ? order.quantity : null"
                  min="1">
                  <template #append>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon
                          v-on="on"
                          :color="order.processed == 'true' ? 'green' : 'red'">
                          {{
                            order.processed == 'true'
                              ? 'mdi-checkbox-marked-circle-outline'
                              : ''
                          }}
                        </v-icon>
                      </template>
                      <span>
                        {{
                          order.processed == 'true'
                            ? 'Order Processed'
                            : 'Ordering Closed'
                        }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="mr-1" cols="2" style="max-width: 150px">
                <div style="display: flex" class="align-center">
                  <v-badge
                    overlap
                    class="font-weight-bold mr-3"
                    color="amber darken-3"
                    :value="getTotalOrders(item.item_orders) > 0"
                    :content="getTotalOrders(item.item_orders)">
                    <v-icon> mdi-cart-outline </v-icon>
                  </v-badge>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn 
                        v-if="!orderingClosed"
                        v-on="on"
                        icon
                        :disabled="getTotalOrders(item.item_orders) < 1"
                        @click="clearItemOrders(item)">
                        <v-icon color='red'>mdi-trash-can</v-icon>
                      </v-btn>
                    </template>
                    <span>Clear Cart</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                       v-if="!orderingClosed"
                       v-on="on"
                       icon
                       :disabled="!getOrderChanged(item.item_orders)"
                       @click="clearItemOrders(item, true)"
                       >
                      <v-icon color="warning">mdi-refresh-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Reset Item to Original Suggestion</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.upc == '0000000000000'" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        class="mx-1"
                        @click="showComponentsModal(item)">
                        <v-icon>mdi-shipping-pallet</v-icon>
                      </v-btn>
                    </template>
                    <span>Components</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-divider
                v-if="
                  Object.entries(item.amap_dates).length > 0 ||
                  Object.entries(item.tpr_dates).length > 0
                "
                vertical
                style="margin-bottom: 2px" />
              <v-col cols="2">
                <template v-if="Object.entries(item.amap_dates).length > 0">
                  <b class="mx-4">AMAP Dates:</b>
                  {{
                    `${formatDate(item.amap_dates.start)} - ${formatDate(
                      item.amap_dates.end
                    )}`
                  }}
                </template>
                <br v-if="item.amap_dates && item.tpr_dates" />
                <template v-if="Object.entries(item.tpr_dates).length > 0">
                  <b class="ml-4">TPR Dates:</b>
                  {{
                    `${formatDate(item.tpr_dates.start)} - ${formatDate(
                      item.tpr_dates.end
                    )}`
                  }}
                </template>
              </v-col>
            </v-row>
          </div>
        </td>
      </template>

      <template #[`item.item_status`]="{ item }">
        <span
          class="noWrap font-weight-bold"
          :class="item.item_status === 'A' ? 'success--text' : 'error--text'">
          {{ item.item_status === 'A' ? 'Active' : 'Discontinued' }}
        </span>
      </template>

      <template #[`item.net_unit_cost`]="{ item }">
        <div style="display: flex; align-items: center;">
          <span color="green">{{ item.net_unit_cost }}</span>
          <v-menu
            v-if="item.deal_cost_dollar_savings != null && item.deal_cost_percent_savings != null"
            open-on-hover
            offset-y
            top
            :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-btn
                class="pa-0 ma-0"
                v-on="on"
                small
                icon
              >
                <v-icon class="ma-0 pa-0">mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <div>
                    <span style="font-weight: bold;">Dollar Savings:</span> 
                    <span style="font-weight: bold; color: #007bff;"> ${{item.deal_cost_dollar_savings}}</span>
                </div>
                <div>
                    <span style="font-weight: bold;">Percent Savings:</span> 
                    <span style="font-weight: bold; color: #007bff;"> {{item.deal_cost_percent_savings}}%</span>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template #[`item.last_90_days_shipped_qty`]="{ item }">
        <span class="noWrap">{{
          item.last_90_days_shipped_qty ? item.last_90_days_shipped_qty : '---'
        }}</span>
      </template>

      <template #[`item.net_case_cost`]="{ item }">
        <span class="noWrap"> ${{ item.net_case_cost }} </span>
      </template>

      <template #[`item.ad_retail`]="{ item }">
        <span class="noWrap">
          {{ item.ad_retail_count }}/{{ item.ad_retail }}
        </span>
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <v-toolbar flat color="toolbar">
          <v-row justify="end" align="center">
            <!-- <v-col cols="2" style="max-width: 150px">
            <v-select
              class="mt-8"
              dense
              :items="pageSizes"
              label="Items Per Page"
              v-model="pageSize"
              :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
            </v-select>
          </v-col> -->
            <v-btn
              icon
              large
              @click="prevPage"
              :disabled="disablePrevious"
              class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>Page {{ page }}</small>
            <v-btn
              icon
              large
              @click="nextPage"
              :disabled="disableNext"
              class="mx-2">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>

      <!-- <template v-slot:body.append="{ headers }">
        <td
          v-if="items.length > 0 && isFetching"
          :colspan="headers.length"
          style="text-align: center; height: 100px">
          <div
            class="my-6"
            v-intersect="{
              handler: onIntersection,
              options: {
                threshold: [0],
                rootMargin: '200px'
              }
            }">
            <div v-if="loadingMore" class="loader">
              <div class="dot dot1"></div>
              <div class="dot dot2"></div>
              <div class="dot dot3"></div>
            </div>
          </div>
        </td>
      </template> -->
    </v-data-table>

    <v-dialog :value.sync="dialog">
      <v-card>
        <v-card-title>
          <v-icon size="35" class="red--text mr-3">mdi-alert-octagon</v-icon>
          <span class="font-weight-light">Unable to Proceed</span>
        </v-card-title>
        <v-card-text>
          No order delivery days are assigned to store.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="text-capitalize font-weight-medium"
            :to="{ path: '/retail-manager' }">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Components -->
    <AlertDialog
      :value.sync="showDialog"
      :options="options"
      @confirm="confirmEvent" />
    <ChartModal
      :value.sync="barChart"
      :item="highlighted"
      :store="store"
      :selected="selected"
      :ad="ad"
      :readonly="readOnlyView"
      @confirmUpdate="confirmUpdate" />
    <ComponentItems
      :value.sync="showComponents"
      :shipper="shipper"
      :key="componentKey" />
    <Loader :value.sync="showLoader" :message="loaderMessage" />
    <FeatureNote :value.sync="showNote" :item="selectedItem" @save="saveNote" />
    <Exports
      :value.sync="exportModal"
      :ad="ad"
      :stores="stores"
      :userEmail="userEmail" />
    <ResetOrderGrid
    v-if="resetOrdersModal"
    :value.sync="resetOrdersModal"
    :ad_id="ad.id"
    :store_id="store.id"
    @refresh-grid="getOrderGridData"
    />
    <!-- ********** -->
  </v-container>
</template>

<script>
// api
import OrderGrid from '@/axios/order-grid'

// mixins
import { displayAlert } from '@/mixins/alert'
// import { fullwidth } from '@/mixins/fullwidth'
import { paging } from '@/mixins/paging'
import { displayHelpers } from '@/mixins/displayHelpers'
import { utils } from '@/mixins/utils'
import { config } from '@/mixins/config'
import { userAccess } from '@/mixins/user-access'

// third-party
import _ from 'lodash'
import { format, subWeeks, parseISO, addDays, startOfWeek } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import store from '../store/index'

export default {
  name: 'OrderView',
  components: {
    AlertDialog: () => import('@/components/dialogs/AlertDialog'),
    SearchFilters: () => import('@/components/SearchFilters'),
    ChartModal: () => import('@/components/charts/ChartModal'),
    ComponentItems: () => import('@/components/ComponentItems.vue'),
    FeatureNote: () => import('@/components/FeatureNote.vue'),
    Exports: () => import('@/components/dialogs/Exports.vue'),
    ResetOrderGrid: () => import('@/components/dialogs/ResetOrderGrid.vue'),
    // ExportModal: () => import('@/components/ExportModal.vue')
    Loader: () => import('@/components/Loader.vue')
  },
  mixins: [config, displayAlert, displayHelpers, paging, utils, userAccess],
  data() {
    return {
      ads: [],
      stores: [],
      ad: {},
      adId: '',
      adPlanner: {},
      barChart: false,
      componentItems: [],
      componentKey: 0,
      departments: [],
      dialog: false,
      expanded: [],
      exportModal: false,
      resetOrdersModal: false,
      filters: [],
      highlighted: {},
      items: [],
      itemStatus: [],
      isFetching: true,
      isCollapsed: false,
      removeFilters: false,
      linkedGroupItems: [],
      loading: true,
      loadingMore: true,
      loaderMessage: 'Loading...',
      options: {},
      orderItem: null,
      orders: [],
      pages: [],
      featureTypes: [],
      featureTexts: [],
      featureNote: '',
      search: '',
      searchParams: {},
      selected: [],
      selectedItem: {},
      shipper: {},
      showComponents: false,
      showDeliveryDates: true,
      showDialog: false,
      showModal: false,
      showNote: false,
      showLoader: false,
      store: {},
      storeDeliveryDays: [],
      tableHeight: '',
      isIntersecting: false,
      isShipper: false,
      quantityFilter: false,
      isHidden: false,
      tableKey: 0,
      status: 'Draft',
      pageSize: 100,
      pageSizes: [100, 250, 500],
      clearFilters: false
    }
  },
  computed: {
    orderingClosed() {
      if (this.ad.ad_date) {
        const dateFormat = 'yyyy-MM-dd'
        const currentDate = utcToZonedTime(new Date(), 'UTC')
        const orderDeadline = addDays(
          subWeeks(
            startOfWeek(utcToZonedTime(this.ad.ad_date, 'UTC'), {
              weekStartsOn: 1
            }),
            6
          ),
          2
        )

        return (
          format(currentDate, dateFormat) > format(orderDeadline, dateFormat)
        )
      }
      return true
    },
    adOrderStatus() {
      const adKeys = Object.keys(this.ad)
      const hasOrderStatus = adKeys.includes('order_status')
      if (hasOrderStatus) {
        return this.ad.order_status.status
      }
      return null
    },
    isAdSubmitted() {
      return this.adOrderStatus === 'Submitted'
    },
    readOnlyView() {
      return this.isAdSubmitted || (!this.isAdSubmitted && this.orderingClosed)
    },
    userEmail() {
      return this.$auth.userEmail
    },
    groupedItems() {
      return this.items.map(item => {
        const combinedKey = `${item.page_name} | ${item.position_name} | ${item.feature_text}`
        return {
          ...item,
          combinedKey
        }
      })
    },
    disablePrevious() {
      return this.page === 1
    },
    disableNext() {
      return this.items.length < this.pageSize
    },
    headers() {
      return [
        {
          sortable: false,
          value: 'data-table-select',
          class: 'accent white--text noWrap',
          show: true
        },
        {
          text: 'Order #',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'item_id',
          show: true
        },
        {
          text: 'Status',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'item_status',
          show: true
        },
        {
          text: 'Item Desc',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'item_desc',
          show: true
        },
        {
          text: 'UPC',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'upc',
          show: true
        },
        {
          text: 'Cost',
          width: '105',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'net_case_cost',
          show: true
        },
        {
          text: 'Unit Cost',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'unit_cost',
          show: true
        },
        {
          text: 'Deal Cost',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'net_unit_cost',
          show: true
        },
        {
          text: 'Reg SRP',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'regular_retail',
          show: true
        },
        {
          text: 'Ad SRP',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'ad_retail',
          show: true
        },
        {
          text: 'GP %',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'ad_gross_profit_percent',
          show: true
        },
        {
          text: 'Division Mvmt',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'last_90_days_shipped_qty',
          show: !this.iProEdgeAccess
        },
        {
          text: 'Department',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'department',
          show: true
        },
        {
          text: 'Pack',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'pack_size',
          show: true
        },
        {
          text: 'Size',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'qty_per_case',
          show: true
        },
        // {
        //   text: 'PQTY',
        //   sortable: true,
        //   filterable: true,
        //   class: 'accent white--text noWrap',
        //   value: 'pallet_qty'
        // },
        {
          text: 'AMAP',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'amap',
          show: true
        },
        {
          text: 'EBA',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'early_buy_billback',
          show: true
        },
        {
          text: 'Ad BB',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'ad_billback',
          show: true
        },
        {
          text: 'TPR BB',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'tpr_billback',
          show: true
        },
        {
          text: 'Ad Scan',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'ad_scan',
          show: true
        },
        {
          text: 'TPR Scan',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'tpr_scan',
          show: true
        },
        {
          text: 'eScan',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'escan',
          show: true
        },
        {
          text: 'eComp',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'ecomp',
          show: true
        },
        {
          text: 'eTPR',
          sortable: true,
          filterable: true,
          class: 'accent white--text noWrap',
          value: 'etpr',
          show: true
        }
        // { text: 'Components', align: 'center', filterable: false, sortable: false, class: 'accent white--text', value: 'components' noWrap }
      ]
    },
    orderingInfo() {
      const adStartDate = new Date(this.adPlanner.ad_start_date)
      const currentDate = format(new Date(), config.date_format)
      return {
        currentDate: currentDate,
        lastOrderDate: format(subWeeks(adStartDate, 4), config.date_format),
        lastShipperOrderDate: format(
          subWeeks(adStartDate, 5),
          config.date_format
        )
      }
    },
    itemFilters() {
      return { ...this.searchParams }
    }
  },
  watch: {
    store: {
      handler(newValue) {
        if (newValue) {
          this.selected = []
          sessionStorage.setItem('order_grid_store', JSON.stringify(newValue))
          this.getAds()
        }
      },
      deep: true
    },
    ad: {
      handler(newValue, oldValue) {
        if (newValue) {
          sessionStorage.setItem('order_grid_ad', JSON.stringify(newValue))
          this.selected = []
          this.items = []
        }
        if (oldValue && newValue !== oldValue) {
          // this.getOrderGridData()
        }
      },
      deep: true
    },
    itemFilters: {
      handler: _.debounce(function (newValue) {
        if (newValue) {
          this.selected = []
          this.page = 1
          this.getOrderGridData()
        }
      }, 250),
      deep: true
    },
    barChart(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.componentKey += 1
      }
    },
    page() {
      this.selected = []
    },
    pageSize() {
      this.page = 1
      this.items = []
    },
    selected(newValue) {
      if (newValue.some(value => value == undefined)) {
        this.selected = []
      }
    },
    showDialog(newValue) {
      if (!newValue) this.options = {}
      this.componenKey++
    }
  },
  async mounted() {
    // this.getAds()
    this.getUserStoresById()
    this.tableHeight = window.innerHeight - 225
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'MM/dd/yyyy')
    },
    openNote(items, feature) {
      this.note = feature
      this.selectedItem = items[0]
      this.showNote = true
    },
    async saveNote(event) {
      const { note, featureType, method } = event
      const requestBody = {
        store_id: this.store.id,
        ad_id: this.ad.id,
        feature_type: featureType,
        note: note,
        email_address: this.userEmail
      }
      try {
        const response = await OrderGrid.saveFeatureNote(requestBody, method)
        if (response.data) {
          store.dispatch('showSnackbar', {
            status: 'success',
            message: 'Note Saved'
          })
        }
      } catch (error) {
        store.dispatch('showSnackbar', {
          status: 'error',
          message: 'Error: Request Failed'
        })
      } finally {
        this.getOrderGridData(false)
      }
    },
    async updateOrderStatus(status) {
      if (this.ad.order_status) this.ad.order_status.status = status
      const body = {
        store_id: this.store.id,
        ad_id: this.ad.id,
        email_address: this.$auth.userEmail,
        status: status
      }
      try {
        await OrderGrid.updateOrderStatus(body)
      } catch (error) {
        console.error(error)
      } finally {
        this.getOrderGridData(true)
      }
    },
    nextPage() {
      this.page += 1
      this.getOrderGridData()
    },
    prevPage() {
      this.page -= 1
      this.getOrderGridData()
    },
    onResize() {
      this.tableHeight = this.windowHeight - 225
    },
    confirmEvent(event) {
      const { method } = event
      switch (method) {
        case 'productLinkedGroupLookup':
          return this.selectLinkedGroupItems()
        case 'updateSuggestedOrder':
          return this.updateSuggestedOrder(event)
        default:
          break
      }
    },
    selectLinkedGroupItems() {
      this.selected = [...this.linkedGroupItems]
    },
    getTotalOrders(orders) {
      const total = orders.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.quantity
      }, 0)
      return total
    },
    getOrderChanged(orders) {
      for (const order of orders){
        if (order.quantity > 0 && order.quantity !== order.suggested_qty || (order.quantity == 0 && order.suggested_qty > 0)){
          return true
        }
      }
      return false
    },
    async clearItemOrders(item, reset=false) {
      try {
        this.loading = true
        for (const order of item.item_orders) {
          if (order.processed == "false" && (order.suggested_qty == 0 || !reset)) {
            console.log("SETTING 0")
            order.quantity = 0
          }
          else if (order.processed == "false" && order.suggested_qty > 0 && reset) {
            console.log("RESETTING TO SUGG")
            order.quantity = order.suggested_qty
          }
        }
        console.log("SAVING")
        await this.updateOrder(item)
      } catch (err) {
        this.handleError(err)
        console.log("ERROR HAPPENED")
      } finally {
        this.loading = false
        console.log("DONE")
      }
    },
    getOrderDeadlines(date) {
      if (!date) {
        return null
      }
      const adDate = parseISO(date)
      const dueDate = addDays(
        subWeeks(
          startOfWeek(utcToZonedTime(adDate, 'UTC'), { weekStartsOn: 1 }),
          6
        ),
        2
      )
      const dueDateFormatted = format(dueDate, 'M/d/yyyy')
      const currentDate = format(
        utcToZonedTime(new Date(), 'UTC'),
        'yyyy-MM-dd'
      )

      const isOpen = currentDate <= format(dueDate, 'yyyy-MM-dd')
      const statusColor = isOpen ? 'success' : 'error'
      return {
        color: statusColor,
        date: dueDateFormatted,
        open: isOpen
      }
    },
    async getUserStoresById() {
      try {
        const response = await OrderGrid.getUserStoresById(this.userEmail)
        if (response.data.length > 0) {
          this.stores = response.data
          if (sessionStorage.getItem('order_grid_store')) {
            const cachedStore = JSON.parse(sessionStorage.order_grid_store)
            const storeExists = this.stores.some(
              obj => obj.id === cachedStore.id
            )
            if (storeExists) {
              this.store = cachedStore
            } else {
              this.store = this.stores[0]
            }
          } else {
            this.store = this.stores[0]
          }
          // this.getAds()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getAds() {
      try {
        const response = await OrderGrid.getAds(this.store.id)
        if (response.data.length > 0) {
          this.ads = response.data
          if (sessionStorage.getItem('order_grid_ad')) {
            const cachedAd = JSON.parse(sessionStorage.order_grid_ad)
            const exists = this.ads.some(obj => obj.id === cachedAd.id)
            if (exists) {
              this.ad = cachedAd
            } else {
              this.ad = this.ads[0]
            }
          } else {
            this.ad = this.ads[0]
            sessionStorage.order_grid_ad = JSON.stringify(this.ad)
          }
          const {
            departments,
            feature_texts,
            feature_types,
            pages,
            itemStatus
          } = this.ad

          this.departments = departments
          this.featureTexts = feature_texts
          this.featureTypes = feature_types
          this.itemStatus = itemStatus
          this.pages = pages.sort((a, b) => a.id - b.id)
        }
      } catch (error) {
        console.log(error)
      }
    },
    onIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadingMore = true
          this.page++
        }
      })
    },
    rowHighlight() {
      return 'grey lighten-4'
    },
    formatDeliveryDate(date) {
      return format(utcToZonedTime(new Date(date)), config.date_delivery)
    },
    formatLabel(date, item) {
      let formatted_date = this.formatDeliveryDate(date)
      let suggested = (item.suggested_qty > 0 && item.suggested_qty != item.quantity && item.quantity > 0) ? ` | Sugg Qty: ${item.suggested_qty}`: ""
      return `${formatted_date}${suggested}`
    },
    isOpenStock(item) {
      const filterText = [
        'ASST',
        'DISP',
        'DSPL',
        'FLRST',
        'HTCH',
        'HUTCH',
        'MD',
        'MOD',
        'PALLET',
        'PLT',
        'QPLT',
        'QTR',
        'SH',
        'SHPR',
        'TOTE'
      ]
      const { upc, item_desc } = item

      const upcCheck = !/^[0]*$/.test(upc)

      const itemDescMissingFilterText = !filterText.some(text =>
        new RegExp(`\\b${text}\\b`, 'i').test(item_desc)
      )
      return upcCheck && itemDescMissingFilterText
    },
    toggleAllItems() {
      if (this.selected.length > 0) {
        this.selected = []
      } else {
        this.items.filter(item => {
          if (this.isOpenStock(item)) {
            this.selected.push(item)
          }
        })
      }
    },
    toggleItemSelection(items) {
      const filteredItems = items.filter(item => this.isOpenStock(item))
      const hasMatch = filteredItems.some(item =>
        this.selected
          .map(selectedItem => selectedItem.item_id)
          .includes(item.item_id)
      )

      if (hasMatch) {
        this.selected = this.selected.filter(selectedItem => {
          return !filteredItems.some(
            item => item.item_id === selectedItem.item_id
          )
        })
      } else {
        this.selected = this.selected.concat(filteredItems)
      }
    },
    addToSelectedItems(isSelected, item) {
      if (!isSelected) {
        this.selected.push(item)
      } else {
        const index = this.selected.findIndex(
          selectedItem => selectedItem.item_id === item.item_id
        )
        this.selected.splice(index, 1)
      }
    },
    // orderingClosed(item) {
    //   const isShipperItem = item.is_shipper
    //   const { currentDate, lastOrderDate, lastShipperOrderDate } =
    //     this.orderingInfo
    //   return (
    //     (isShipperItem && currentDate > lastShipperOrderDate) ||
    //     currentDate > lastOrderDate
    //   )
    // },
    async getProductLinkedGroup(item) {
      const { item_id } = item
      const supplier_id = this.ad.supplier_id
      let count = 0
      try {
        const response = await OrderGrid.getProductLinkedGroup(
          item_id,
          supplier_id
        )
        if (response.data.length > 0) {
          count = response.data.length
          this.linkedGroupItems = this.items.filter(item =>
            response.data.some(
              res => res.orderCode === item.item_id && this.isOpenStock(item)
            )
          )
        }
        count = this.linkedGroupItems.length
      } catch (error) {
        this.options.count = 0
        console.log(error)
      }
      let message = 'Results Found'
      if (count > 0) {
        message = `
        ${count} ${message} <br />
        Would you like to select results?
      `
      } else {
        message = `${count} ${message}`
      }

      this.options = {
        icon: 'mdi-magnify',
        title: 'Product Link Group Lookup',
        message: message,
        event: 'productLinkedGroupLookup',
        showConfirm: count > 0
      }
      this.showDialog = true
    },
    itemsInGroup(items) {
      if (this.selected.length > 0) {
        const selectedItems = this.selected.map(item => {
          if (item) {
            return item.item_id
          }
        })
        return items.some(item => item && selectedItems.includes(item.item_id))
      }
    },
    showComponentsModal(item) {
      this.showComponents = true
      this.shipper = item
    },
    async getOrderGridData(refresh) {
      this.showLoader = refresh
      this.loading = true
      if (!this.ad) return
      const offset = (this.page - 1) * this.pageSize
      const limit = this.pageSize
      try {
        const response = await OrderGrid.getOrderGridData(
          this.ad.id,
          this.store.id,
          offset,
          limit,
          this.itemFilters
        )
        if (response.data.length > 0) {
          this.items = response.data
          this.expanded = response.data
        }
        else {
          this.items = []
          this.expanded = []
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.showLoader = false
        this.loading = false
      }
    },
    buildPromoListParams() {
      let params = {}
      if (Object.entries(this.itemFilters).length > 0) {
        params = { ...this.itemFilters }
      }
      return params
    },
    getItemTotalOrdered(item) {
      const totalOrdered = item.item_orders.reduce(
        (total, order) => total + (order.quantity || 0),
        0
      )
      item.total_ordered = totalOrdered
      return item
    },
    mapItemOrder(item, order, quantityValue) {
      let quantity = quantityValue ? parseInt(quantityValue) : 0
      const updatedOrders = item.item_orders.map(itemOrder => {
        if (itemOrder.delivery_date === order.delivery_date) {
          itemOrder.quantity = quantity
          return { ...itemOrder, quantity }
        }
        return itemOrder
      })

      return { ...item, item_orders: updatedOrders }
    },
    selectMethod(item, order, quantity) {
      if (order.order_id) {
        if (
          quantity !== undefined &&
          quantity !== null &&
          order.quantity !== quantity
        ) {
          const updatedOrder = this.mapItemOrder(item, order, quantity)
          return this.updateOrder(updatedOrder)
        } else {
          const deleteOrder = this.mapItemOrder(item, order, quantity)
          return this.deleteOrder(deleteOrder)
        }
      } else {
        const newOrder = this.mapItemOrder(item, order, quantity)
        return this.createOrder(newOrder)
      }
    },
    buildRequestBody(item) {
      return {
        store_no: this.store.id,
        ad_id: this.ad.id,
        user_email: this.userEmail,
        item_desc: item.item_desc,
        item_id: item.item_id,
        item_orders: item.item_orders
      }
    },
    async createOrder(item) {
      try {
        const requestBody = this.buildRequestBody(item)
        await OrderGrid.createOrder(requestBody)
      } catch (error) {
        console.error(error)
        this.handleError("An error occured while saving the order value. Please refresh the page and try again.")
      } finally {
        // await this.getOrderGridData(false) 
      }
    },
    async updateOrder(item) {
      try {
        const requestBody = this.buildRequestBody(item)
        await OrderGrid.updateOrder(requestBody)
      } catch (error) {
        console.error(error)
      } finally {
        // await this.getOrderGridData(false)
      }
    },
    async deleteOrder(item) {
      try {
        const requestBody = this.buildRequestBody(item)
        await OrderGrid.deleteOrder(requestBody)
      } catch (error) {
        console.error(error)
      } finally {
        await this.getOrderGridData(false)
      }
    },
    confirmUpdate(options) {
      this.options = { ...options }
      this.showDialog = true
    },
    async updateSuggestedOrder(event) {
      this.barChart = false
      this.showLoader = true
      this.loaderMessage = 'Updating...'
      const { data } = event
      const startDate = data[0]
      const endDate = data[1]
      const upc_list = this.selected.map(({ upc }) => upc)
      const body = {
        start_date: startDate,
        end_date: endDate,
        store: this.store.id,
        ad_id: this.ad.id,
        email_address: this.$auth.user.email,
        upcs: upc_list
      }
      try {
        await OrderGrid.updateSuggestedOrder(body)
      } catch (error) {
        console.error(error)
      } finally {
        this.getOrderGridData(false)
        this.showLoader = false
      }
    }
  }
}
</script>
<style>
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: white;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
  background: #f5f5f5;
}
.deliveryDay.v-text-field--outlined.v-input--dense .v-label--active {
  color: #2589bd !important;
  font-weight: bold;
}
.text-start {
  border-top: 1px solid #9e9e9e !important;
  border-bottom: none !important;
}
.noWrap {
  white-space: nowrap !important;
}
</style>
