var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$auth.isLoading || !_vm.$auth.isAuthenticated
    ? _c(
        "v-overlay",
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      )
    : _c(
        "v-app",
        [
          _c(
            "v-app-bar",
            { attrs: { app: "", color: "main", dark: "" } },
            [
              _c("v-img", {
                attrs: {
                  "max-height": "75",
                  "max-width": "100",
                  src: require("@/assets/arg_logo_white.png"),
                  alt: "ARG Logo",
                },
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize",
                  attrs: { text: "" },
                  on: { click: _vm.logout },
                },
                [
                  _vm._v(" Sign Out "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-logout-variant"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-main",
            { staticClass: "toolbar" },
            [
              _c("alertbar"),
              _vm.hasPortalAccess
                ? _c("router-view")
                : _c(
                    "v-alert",
                    { attrs: { type: "error", text: "", border: "left" } },
                    [
                      _vm._v(
                        " You don't have authorized roles to access this site. "
                      ),
                    ]
                  ),
              _c(
                "v-snackbar",
                {
                  staticStyle: { top: "70px" },
                  attrs: {
                    top: "",
                    rounded: "md",
                    height: "60",
                    "content-class": "body-1",
                    transition: "scale-transition",
                    timeout: _vm.snackbar.timeout,
                    color: _vm.snackbar.color,
                  },
                  model: {
                    value: _vm.snackbar.showing,
                    callback: function ($$v) {
                      _vm.$set(_vm.snackbar, "showing", $$v)
                    },
                    expression: "snackbar.showing",
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.snackbar.icon)),
                  ]),
                  _vm._v(" " + _vm._s(_vm.snackbar.message) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-footer",
            {
              staticClass: "justify-space-between",
              attrs: { color: "main", app: "" },
            },
            [
              _c("span", { staticClass: "white--text" }, [
                _vm._v(" iPro "),
                _c("span", { staticClass: "registeredTrademark" }, [
                  _vm._v("®"),
                ]),
                _vm._v(
                  " Systems, LLC. © " + _vm._s(new Date().getFullYear()) + " "
                ),
              ]),
            ]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }