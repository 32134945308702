import moment from 'moment'

export const displayHelpers = {
  filters: {
    formatCurrency: function (value) {
      if (value == null) return '0.00'
      return parseFloat(value).toFixed(2)
    }
  },
  methods: {
    formatDateDisplay (date) {
      return date ? moment(date, 'YYYY-MM-DD').format(this.$dateConfig.date_display) : ''
    },
    formatDateTimestamp (date) {
      return date ? moment.utc(date, 'YYYY-MM-DD').local().format(this.$dateConfig.timestamp) : ''
    },
    formatCurrencyDisplay (value) {
      return parseFloat(value).toFixed(2)
    },
    formatDateNoTimezone (date) {
      // e.g. "12/14/2021"
      const dateStr = new Date(date).toISOString().substr(0, 10)
      const [year, month, day] = dateStr.split('-')
      return [month, day, year].join('/')
    },
    formatDateTimeAlt (date) {
      // e.g. "12/14/21, 1:30 PM" (using local timezone)
      return new Date(date).toLocaleString('en', {
        dateStyle: 'short',
        timeStyle: 'short'
      })
    },
    formatDateISO (date) {
      // e.g. 2022-01-18
      if (date) return new Date(date).toISOString().substr(0, 10)
    },
    getFutureDate (numDaysToAdd) {
      const today = new Date().setHours(0, 0, 0, 0)
      const dateObj = new Date(today)
      dateObj.setDate(dateObj.getDate() + numDaysToAdd)
      return dateObj.toISOString().substr(0, 10)
    }
  }
}
